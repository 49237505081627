import React from "react";
import QRCode from "react-qr-code";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useParams,
} from "react-router-dom";
import "./App.css";

function QRDisplay() {
  const { qrId } = useParams();

  return (
    <div className="QRDisplay">
      <h2>QR Kodunuz</h2>
      <h3>{qrId}</h3>
      <div className="qr-container">
        <QRCode value={qrId} size={256} />
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/:qrId" element={<QRDisplay />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
